import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["listRef", "nextButtonRef", "previousButtonRef", "carouselIdRef", "classNames", "itemComponent", "previousIconComponent", "nextIconComponent", "items", "translations", "sendEvent"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { cx } from "../lib/index.js";
import { createDefaultItemComponent } from "./recommend-shared/index.js";
var lastCarouselId = 0;
export function generateCarouselId() {
  return "ais-Carousel-".concat(lastCarouselId++);
}
function PreviousIconDefaultComponent(_ref) {
  var createElement = _ref.createElement;
  return createElement("svg", {
    width: "8",
    height: "16",
    viewBox: "0 0 8 16",
    fill: "none"
  }, createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "currentColor",
    d: "M7.13809 0.744078C7.39844 1.06951 7.39844 1.59715 7.13809 1.92259L2.27616 8L7.13809 14.0774C7.39844 14.4028 7.39844 14.9305 7.13809 15.2559C6.87774 15.5814 6.45563 15.5814 6.19528 15.2559L0.861949 8.58926C0.6016 8.26382 0.6016 7.73618 0.861949 7.41074L6.19528 0.744078C6.45563 0.418641 6.87774 0.418641 7.13809 0.744078Z"
  }));
}
function NextIconDefaultComponent(_ref2) {
  var createElement = _ref2.createElement;
  return createElement("svg", {
    width: "8",
    height: "16",
    viewBox: "0 0 8 16",
    fill: "none"
  }, createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "currentColor",
    d: "M0.861908 15.2559C0.601559 14.9305 0.601559 14.4028 0.861908 14.0774L5.72384 8L0.861908 1.92259C0.601559 1.59715 0.601559 1.06952 0.861908 0.744079C1.12226 0.418642 1.54437 0.418642 1.80472 0.744079L7.13805 7.41074C7.3984 7.73618 7.3984 8.26382 7.13805 8.58926L1.80472 15.2559C1.54437 15.5814 1.12226 15.5814 0.861908 15.2559Z"
  }));
}
export function createCarouselComponent(_ref3) {
  var createElement = _ref3.createElement,
    Fragment = _ref3.Fragment;
  return function Carousel(userProps) {
    var listRef = userProps.listRef,
      nextButtonRef = userProps.nextButtonRef,
      previousButtonRef = userProps.previousButtonRef,
      carouselIdRef = userProps.carouselIdRef,
      _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      _userProps$itemCompon = userProps.itemComponent,
      ItemComponent = _userProps$itemCompon === void 0 ? createDefaultItemComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$itemCompon,
      _userProps$previousIc = userProps.previousIconComponent,
      PreviousIconComponent = _userProps$previousIc === void 0 ? PreviousIconDefaultComponent : _userProps$previousIc,
      _userProps$nextIconCo = userProps.nextIconComponent,
      NextIconComponent = _userProps$nextIconCo === void 0 ? NextIconDefaultComponent : _userProps$nextIconCo,
      items = userProps.items,
      userTranslations = userProps.translations,
      sendEvent = userProps.sendEvent,
      props = _objectWithoutProperties(userProps, _excluded);
    var translations = _objectSpread({
      listLabel: 'Items',
      nextButtonLabel: 'Next',
      nextButtonTitle: 'Next',
      previousButtonLabel: 'Previous',
      previousButtonTitle: 'Previous'
    }, userTranslations);
    var cssClasses = {
      root: cx('ais-Carousel', classNames.root),
      list: cx('ais-Carousel-list', classNames.list),
      item: cx('ais-Carousel-item', classNames.item),
      navigation: cx('ais-Carousel-navigation', classNames.navigation),
      navigationNext: cx('ais-Carousel-navigation--next', classNames.navigationNext),
      navigationPrevious: cx('ais-Carousel-navigation--previous', classNames.navigationPrevious)
    };
    function scrollLeft() {
      if (listRef.current) {
        listRef.current.scrollLeft -= listRef.current.offsetWidth * 0.75;
      }
    }
    function scrollRight() {
      if (listRef.current) {
        listRef.current.scrollLeft += listRef.current.offsetWidth * 0.75;
      }
    }
    function updateNavigationButtonsProps() {
      if (!listRef.current || !previousButtonRef.current || !nextButtonRef.current) {
        return;
      }
      previousButtonRef.current.hidden = listRef.current.scrollLeft <= 0;
      nextButtonRef.current.hidden = listRef.current.scrollLeft + listRef.current.clientWidth >= listRef.current.scrollWidth;
    }
    if (items.length === 0) {
      return null;
    }
    return createElement("div", _extends({}, props, {
      className: cx(cssClasses.root)
    }), createElement("button", {
      ref: previousButtonRef,
      title: translations.previousButtonTitle,
      "aria-label": translations.previousButtonLabel,
      hidden: true,
      "aria-controls": carouselIdRef.current,
      className: cx(cssClasses.navigation, cssClasses.navigationPrevious),
      onClick: function onClick(event) {
        event.preventDefault();
        scrollLeft();
      }
    }, createElement(PreviousIconComponent, {
      createElement: createElement
    })), createElement("ol", {
      className: cx(cssClasses.list),
      ref: listRef,
      tabIndex: 0,
      id: carouselIdRef.current,
      "aria-roledescription": "carousel",
      "aria-label": translations.listLabel,
      "aria-live": "polite",
      onScroll: updateNavigationButtonsProps,
      onKeyDown: function onKeyDown(event) {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollLeft();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollRight();
        }
      }
    }, items.map(function (item, index) {
      return createElement("li", {
        key: item.objectID,
        className: cx(cssClasses.item),
        "aria-roledescription": "slide",
        "aria-label": "".concat(index + 1, " of ").concat(items.length),
        onClick: function onClick() {
          sendEvent('click:internal', item, 'Item Clicked');
        },
        onAuxClick: function onAuxClick() {
          sendEvent('click:internal', item, 'Item Clicked');
        }
      }, createElement(ItemComponent, {
        item: item
      }));
    })), createElement("button", {
      ref: nextButtonRef,
      title: translations.nextButtonTitle,
      "aria-label": translations.nextButtonLabel,
      "aria-controls": carouselIdRef.current,
      className: cx(cssClasses.navigation, cssClasses.navigationNext),
      onClick: function onClick(event) {
        event.preventDefault();
        scrollRight();
      }
    }, createElement(NextIconComponent, {
      createElement: createElement
    })));
  };
}