export function createListComponent(_ref) {
  var createElement = _ref.createElement;
  return function List(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      ItemComponent = userProps.itemComponent,
      items = userProps.items,
      sendEvent = userProps.sendEvent;
    return createElement("div", {
      className: classNames.container
    }, createElement("ol", {
      className: classNames.list
    }, items.map(function (item) {
      return createElement("li", {
        key: item.objectID,
        className: classNames.item,
        onClick: function onClick() {
          sendEvent('click:internal', item, 'Item Clicked');
        },
        onAuxClick: function onAuxClick() {
          sendEvent('click:internal', item, 'Item Clicked');
        }
      }, createElement(ItemComponent, {
        item: item
      }));
    })));
  };
}